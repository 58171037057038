.orderItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--info-block-two-background-color) !important;

  .MuiCardMedia-img {
    max-width: 100px;
    max-height: 100px;
  }

  .MuiTypography-h5 {
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .orderItemPriceContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .orderItemQuantity {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .MuiInputBase-root {
        width: 100px;
      }
    }
  }

  .orderItemTitleContainer {
    display: flex;
    flex-direction: column;
    padding-left: 15px;

    .orderItemTitle {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .orderItemPrice {
    color: var(--tertiary-color);
    font-family: 'Protest Riot' !important;
    padding: 0 5px 0 5px;
  }

  .orderItemTitle {

  }
}

@media (max-width: 599px) {
  .orderItem .orderItemPriceContainer {
    padding-left: 0;
    margin-top: 20px;

    .orderItemQuantity {
      display: flex;
      flex: 1;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }

  .orderItem .orderItemTitleContainer {
    padding-left: 0;
    margin-top: 20px;
  }
}