.checkoutTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  .checkoutTitleIcon {
    width: 30px;
    height: 30px;
    color: var(--primary-color);
  }

  .checkoutTitleText {
    margin-left: 15px;
  }
}

.checkoutScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.checkoutForm {
  background-color: var(--info-block-two-background-color) !important;
}

.checkoutContent {
  background-color: var(--info-block-one-background-color) !important;
  overflow: auto;
}

.checkoutFormBlock {
  display: flex;
  flex-direction: column;
}

.checkoutContentDivider {
  margin: 16px 0 !important;
}

.emptyCartItems {
  padding: 16px;
  background-color: var(--info-block-two-background-color) !important;
}

.checkoutActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.checkoutLoading {
  color: var(--white-color);
  margin-right: 15px;
  width: 20px !important;
  height: 20px !important;
}

.checkoutErrors {
  padding: 16px;
}
.checkoutError {
  color: var(--error) !important;
  margin-top: 16px;
  font-weight: bold;
}