.pageBreadCrumbs {
  padding: 16px;

  .activePageBreadCrumb {
    color: var(--selected-color) !important;
    text-transform: capitalize;
  }

  .pageBreadCrumb {
    text-transform: capitalize;
  }
}

