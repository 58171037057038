@use 'colors' as *;

:root {
  --primary-color: #{$primary};
  --primary-color-transparent: #{$primary-transparent};
  --secondary-color: #{$secondary};

  --selected-color: #{$selected};

  --white-color: #{$white};

  --scrollbar-thumb: #{$scroll-thumb};

  --info-block-one-background-color: #{$info-block-one-background-color};
  --info-block-two-background-color: #{$info-block-two-background-color};

  --error: #{$error}
}

html[data-theme="dark"] {
}

html[data-theme="light"] {
}