.accountScreen {
  display: flex;
  flex: 1;
  padding: 16px;
}

.accountScreenHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .MuiButton-root {
    margin: 0 !important;
  }
}

.accountScreenHeaderName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accountScreenHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  overflow: hidden;
  margin-right: 8px;
  color: var(--white-color);
  font-weight: bold;
  font-size: 14px;
}