.orderModalContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.orderModal {
  margin: 16px;
  background-color: var(--white-color);
  overflow: auto;
  border-radius: 15px;
  padding: 16px;
}

.orderModalHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 0;
}

.orderModalDetails {
  border-radius: 15px !important;
  margin-bottom: 16px;
  background-color: var(--info-block-two-background-color) !important;

  th {
    font-weight: bold;
  }

  th, td {
    font-family: "Nunito Sans";
    vertical-align: top;
  }
}