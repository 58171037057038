$xs_breakpoint: 480px;
$sm_breakpoint: 768px;
$md_breakpoint: 1024px;
$lg_breakpoint: 1200px;

@media (max-width: $xs_breakpoint) {
  #header {
    height: 52px;

    .logoText {
      font-size: 18px;
    }

    .logoImage {
      height: 24px;
    }
  }

  .cartModal {
    max-width: 95%;
    max-height: 95%;
  }

  .orderModal {
    max-width: 95%;
    max-height: 95%;
  }
}

@media (min-width: $xs_breakpoint) and (max-width: $sm_breakpoint) {
  #header {
    height: 54px;

    .logoText {
      font-size: 22px;
    }

    .logoImage {
      height: 30px;
    }
  }

  .cartModal {
    max-width: 95%;
    max-height: 95%;
  }

  .orderModal {
    max-width: 95%;
    max-height: 95%;
  }
}

@media (min-width: $sm_breakpoint) and (max-width: $md_breakpoint) {
  #header {
    height: 56px;

    .logoText {
      font-size: 24px;
    }

    .logoImage {
      height: 32px;
    }
  }

  .cartModal {
    max-width: 75%;
    max-height: 75%;
  }

  .orderModal {
    max-width: 75%;
    max-height: 75%;
  }
}

@media (min-width: $md_breakpoint) and (max-width: $lg_breakpoint) {
  #header {
    height: 58px;

    .logoText {
      font-size: 26px;
    }

    .logoImage {
      height: 34px;
    }
  }

  .cartModal {
    max-width: 75%;
    max-height: 75%;
  }

  .orderModal {
    max-width: 75%;
    max-height: 75%;
  }
}

@media (min-width: $lg_breakpoint) {
  #header {
    height: 60px;

    .logoText {
      font-size: 28px;
    }

    .logoImage {
      height: 36px;
    }
  }

  .cartModal {
    max-width: 50%;
    max-height: 50%;
  }

  .orderModal {
    max-width: 75%;
    max-height: 75%;
  }
}

b i span {
  color: var(--primary-color);
}

.hoverBlock:hover {
  cursor: pointer;
  animation: pulse 2s infinite;
  background-color: var(--info-block-one-background-color) !important;
  transition: background-color 2s ease;
}

@keyframes pulse {
  0% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 var(--selected-color);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.divider {
  margin: 0 15px 0 15px !important;
}

// adjustments for all UI components

.MuiCard-root {
  border-radius: 15px !important;
}

.MuiCardMedia-img {
  filter: drop-shadow(15px 15px 2.5px #00000025);
}

.MuiBadge-badge {
  background-color: var(--selected-color) !important;
}

.MuiTypography-root {
  font-family: 'Nunito Sans', 'Roboto','Helvetica','Arial', 'sans-serif' !important;
  color: var(--primary-color) !important;
}

.signAvatar {
  background-color: var(--selected-color) !important;
  width: 50px !important;
  height: 50px !important;
  margin-bottom: 16px;
}

.signAvatarLogo {
  fill: var(--primary-color) !important;
  width: 30px;
  height: 30px;
}


