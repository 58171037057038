@use 'assets/scss/themes' as *;

@font-face {
  font-family: 'Protest Riot';
  src: url(./assets/fonts/ProtestRiot-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Yanone Kaffeesatz';
  src: url(./assets/fonts/YanoneKaffeesatz-VariableFont_wght.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(./assets/fonts/NunitoSans.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Semi Bold';
  src: url(./assets/fonts/Nunito-SemiBold.ttf) format('truetype');
  font-display: swap;
}

html, body, #root, .layout {
  width: 100%;
  height: 100%;
}

html, body, #root, .layout, .cartModal, .orderModal {
  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--white-color);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: var(--scrollbar-thumb);
  }
}

body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  display: flex;
  flex: 1;
}