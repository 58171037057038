#header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 16px;
  background-color: var(--primary-color);

  //&.sticky {
  //  position: fixed;
  //  top: 0;
  //  height: 0;
  //  overflow: hidden;
  //  width: calc(100% - 32px);
  //  z-index: 1;
  //}
  //
  //&.showSticky {
  //  height: 80px;
  //  transition: height 100ms;
  //}

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .logoImage {
    fill: var(--white-color);
  }

  .logoText {
    margin-left: 15px;
    color: var(--white-color);
  }

  .headerAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin-left: 8px;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 14px;
    
    &:hover {
      cursor: pointer;
    }

    .headerAvatarIcon {
      color: var(--primary-color)
    }
  }

  .headerCartIcon {
    color: var(--white-color);
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}