.cartTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  .cartTitleIcon {
    width: 30px;
    height: 30px;
    color: var(--primary-color);
  }

  .cartTitleText {
    margin-left: 15px;
  }
}

.cartScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cartContent {
  background-color: var(--info-block-one-background-color) !important;
}

.cartContentDivider {
  margin: 16px 0 !important;
}

.emptyCartItems {
  padding: 16px;
  background-color: var(--info-block-two-background-color) !important;
}