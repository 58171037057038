.productCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--info-block-two-background-color) !important;
  min-height: 540px;

  .MuiCardMedia-img {
    max-width: 200px;
    max-height: 200px;
  }

  .MuiTypography-h5:first-letter {
      text-transform: uppercase;
  }
}

.bigProductCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .MuiCardMedia-img {
    max-width: 200px;
    max-height: 200px;
  }

  .MuiTypography-h5:first-letter {
    text-transform: uppercase;
  }

  .productCardMedia {
    background-color: var(--info-block-two-background-color);
  }
}

.productCardActions {
  display: flex;
  flex: 1;
  width: calc(100% - 32px);
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-end;
  justify-content: space-between;
  margin: 16px;

  .productCardPrice {
    color: var(--primary-color);
    font-family: 'Protest Riot';;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.productCardMedia {
  background-color: var(--white-color);
  width: calc(100% - 64px);
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 16px;
}

.productCardMediaSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  margin: 16px;
}

.productCardMediaSelectBlock {
  width: 80px;
  height: 80px;
  background-color: var(--info-block-two-background-color);
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  margin-right: 8px;
}