.cartModalContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.cartModal {
  padding: 16px;
  margin: 16px;
  background-color: var(--white-color);
  border-radius: 15px;
}

.cartModalActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
}