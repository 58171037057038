.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  color: var(--white-color);
  background-color: var(--primary-color);

  .footerBlock {
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: var(--white-color);
    margin: 16px 0 16px 16px;
    padding: 16px;

    &:last-of-type {
      margin: 16px;
    }
  }

  .footerBlockIcon {
    width: 50px;
    height: 50px;
    color: var(--primary-color);
    margin-right: 16px;
  }
}